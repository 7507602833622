<template>
  <div class="Awarp">
    <dashCard
        class="carbonBox6"
        :operateWidth="operateWidth"
        :isLoading="isLoading"
        :gridOption="gridOption"
        @changeScreen="changeScreen"
    >
      <template slot="title">{{commonLangHandler('carbonBox6_analyze','碳排放分析', getZEdata)}}</template>
      <template slot="aside">
        <div class="card-content3">
          <div class="type-group">
            <span
                class="pick-type"
                v-for="(item, idx) in timetType"
                :key="idx"
                @click="changeType(item.code)"
            >
              <font :class="{ 'isactive-type': item.code == searchObj.TYPE }">{{
                  item.name
                }}</font>
            </span>
          </div>
          <a-month-picker
              v-if="searchObj.TYPE == '按月'"
              :placeholder="commonLangHandler('chuneng7_month','选月', getZEdata)"
              v-model="searchObj.VALUE"
              @change="onChange"
          />
          <a-date-picker
              v-if="searchObj.TYPE == '按年'"
              :placeholder="commonLangHandler('chuneng7_year','选年', getZEdata)"
              v-model="searchObj.VALUE"
              @change="onChange"
              mode="year"
              :open="panelOpen"
              @openChange="openChange"
              @panelChange="panelChange"
              format="YYYY"
          />
          <div style="width: 120px" v-if="searchObj.TYPE == '按季'">
            <el-select
                v-model="value"
                :placeholder="commonLangHandler('operationAm5_select','请选择', getZEdata)"
                @change="handleChange"
            >
              <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
              >
              </el-option>
            </el-select>
          </div>
        </div>
      </template>

      <div class="card-content1">
        <chartBoard ref="chart1" :option="chartOption1" />
      </div>
    </dashCard>
    <a-modal
        v-if="!isModal"
        class="Amodal"
        v-model="option.visible"
        :closable="false"
        :footer="null"
        :keyboard="false"
        width="100%"
        :dialog-style="{ top: '0px' }"
    >
      <div style="height: calc(100vh)">
        <carbonBox6
            :option="option"
            :isModal="true"
            :orginSearchObj="searchObj"
        />
      </div>
    </a-modal>
  </div>
</template>
<script>
export default {
  name: "carbonBox6",
  components: {
    dashCard: () => import("@/component/dashCard.vue"),
    chartBoard: () => import("@/component/chartBoard.vue"),
  },
  props: {
    gridOption: {
      type: Object,
      default: function () {
        return { w: 6, h: 6 };
      },
    },
    option: {
      type: Object,
      default: function () {
        return {
          visible: false,
        };
      },
    },
    isModal: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
    orginSearchObj: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  watch: {
    gridOption: {
      handler: function (newVal, oldVal) {
        this.$nextTick((_) => {
          this.getOperateWidth();
          var dom = this.$refs["chart1"];
          if (!!dom) {
            dom.handleWindowResize();
          }
        });
      },
      deep: true,
      immediate: true,
    },
    "option.visible": {
      //全屏化时执行
      handler: function (newVal, oldVal) {
        if (newVal) {
          if (this.isModal) {
            this.searchObj = this.orginSearchObj;
            this.getData();
          }
        }
      },
      deep: true,
      immediate: true,
    },
    "currentSelectDeptInfo.CODE": {
      handler: function (newVal, oldVal) {
        if (oldVal) {
          this.getData();
        }
      },
      deep: true,
      immediate: true,
    },
  },
  data() {
    return {
      operateWidth: 64, //操作列宽度
      isLoading: false, //数据加载中

      panelOpen: false,
      searchObj: {
        VALUE: this.$moment(), //日期
        TYPE: "按年",
      },
      timetType: [
        {
          code: "按年",
          name: this.LangHandler('byyears','按年'),
        },
      ],
      options: [
        {
          value: "1",
          label: "第一季度",
        },
        {
          value: "2",
          label: "第二季度",
        },
        {
          value: "3",
          label: "第三季度",
        },
        {
          value: "4",
          label: "第四季度",
        },
      ],
      quarter: false,
      value: "1",
      chartOption1: {},
      detailInfo: {},
      colorList: [
        "#7CB5EC",
        "#444449",
        "#90ED7D",
        "#B0E2FF",
        "#696969",
        "#98FB98",
      ],
    };
  },
  computed: {
    currentSelectMenuInfo() {
      return this.$store.state.currentSelectMenuInfo;
    },
    appDictData() {
      return this.currentSelectMenuInfo.appDictData;
    },
    currentSelectDeptInfo() {
      return this.$store.state.currentSelectDeptInfo;
    },
  },
  methods: {
    // 全屏切换
    changeScreen() {
      this.option.visible = !this.isModal;
      if (!this.option.visible) {
        this.getData();
      }
    },
    // 右侧操作列宽度
    getOperateWidth() {
      if (!this.$el.querySelector) {
        return;
      }
      var dom = this.$el.querySelector(".card-head-operate");
      if (!!dom) {
        var width = dom.clientWidth;
        this.operateWidth = width > 0 ? width + 12 : width;
      }
    },

    openChange(status) {
      this.panelOpen = status;
    },
    // 年份弹窗关闭
    panelChange(value) {
      this.searchObj.VALUE = value;
      //console.log(this.searchObj.VALUE);
      this.panelOpen = false;
      this.getData();
    },
    onChange() {
      this.getData();
    },
    // handleChange(value) {
    //     //console.log("handleChange", value, this.searchObj)
    // },
    transformSearch: function () {
      var TYPE = this.searchObj.TYPE;
      var dateStr = this.searchObj.VALUE.format("YYYY-MM-DD");
      var VALUE = this.$moment(dateStr);
      var obj = {
        按年: {
          // type: "year",
          starttime: VALUE.startOf("year").format("YYYY-MM"),
          endtime: VALUE.endOf("year").format("YYYY-MM"),
        },
      };
      return obj[TYPE];
    },
    changeType(type) {
      if (type == "按季") {
        this.handleChange();
      }
      this.searchObj.TYPE = type;
      this.getData();
    },
    handleChange(value) {
      if (!!value) this.value = value;
      this.getData();
    },
    getTimes(value) {
      var date = new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;
      let obj = {
        starttime: "",
        endtime: "",
      };
      if (value == "1") {
        obj.starttime = year + "-01-01 00:00:00";
        obj.endtime = year + "-03-31 23:59:59";
      } else if (value == "2") {
        obj.starttime = year + "-04-01 00:00:00";
        obj.endtime = year + "-06-30 23:59:59";
      } else if (value == "3") {
        obj.starttime = year + "-07-01 00:00:00";
        obj.endtime = year + "-09-30 23:59:59";
      } else if (value == "4") {
        obj.starttime = year + "-10-01 00:00:00";
        obj.endtime = year + "-12-31 23:59:59";
      }
      return obj;
    },
    //每月报警预警数
    getData() {
      this.isLoading = true;
      let url =
          Config.dev_url + "/api-apps-v2/api/v2/carbon_emissions/analysis";
      let data = {
        code: this.currentSelectDeptInfo.CODE,
        // starttime: "2023-01",
        // endtime: "2023-12"
      };
      if (this.searchObj.TYPE == "按季") {
        Object.assign(data, this.getTimes(this.value));
      } else {
        Object.assign(data, this.transformSearch());
      }
      this.$axios
          .post(url, data)
          .then((res) => {
            //console.log(res);
            this.detailInfo = {};
            var chart1 = {
              seriesData: [],
              indicator: [],
            };
            // let max = Math.max.apply(null, res.data.data.seriesData[0].value);
            let maxarr = [];
            for (let item of res.data.data.seriesData) {
              for (let item2 of item.value) {
                maxarr.push(item2);
              }
            }
            let max = Math.max.apply(null, maxarr);

            // let max = Math.max.apply(null, res.data.data.seriesData[0].value);
            if (res.data.data.categoryData !== []) {
              let lang = sessionStorage.getItem("locale") || 'CH'
              if(lang=='EN'){
           
              res.data.data.categoryData.forEach((item) => {
                if(item == "电力"){
                  item = "power"
                }
                if(item == "热力"){
                  item = "Thermodynamics"
                }
                if(item == "工业过程"){
                  item = "industrial emission"
                }
                // //console.log(item);
                chart1.indicator.push({ text: item, max: max });
              });
            } 
            }
            // //console.log(chart1.indicator);
            chart1.seriesData = res.data.data.seriesData;
            this.detailInfo.chart1 = chart1;
            this.initEchart();
          })
          .catch((wrong) => {})
          .finally(() => {
            this.isLoading = false;
          });
    },
    initEchart() {
      var { indicator, seriesData } = this.detailInfo.chart1;
      var option = {
        animation: true,
        layoutAnimation: false,
        legend: {
          orient: "vertical", //horizontal vertical
          icon: "rect", //图例形状
          top: "0%",
          right: 0,
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            fontFamily: "ABBvoice_WCNSG_Rg",
            color: "#1d2129",
          },
          // itemGap: 100,
        },
        tooltip: {
          // CO₂
          formatter: function (params) {
            let str = "";
            str += params.name + "<br>";
            // indicator.forEach(item=>{
            //     //console.log(item);
            //     str += item.text + ":" + params.value[i]  + "<br>"
            // })
            for (let i = 0; i < indicator.length; i++) {
              str +=
                  "<div style='width: 100%;display: flex;justify-content: space-between;align-items: center'>" +
                  "<span>" +
                  indicator[i].text +
                  " : </span>" +
                  "<span>" +
                  params.value[i].toFixed(2) +
                  "</span>" +
                  "<span>" +
                  " tCO₂" +
                  "</span>" +
                  "</div>";
            }
            return str;
          },
        },
        radar: {
          name: {
            textStyle: {
              fontFamily: "ABBvoice_WCNSG_Rg",
              color: "#000000",
              fontSize: 14,
            },
          },
          indicator: indicator,
          splitArea: {
            // 坐标轴在 grid 区域中的分隔区域，默认不显示。
            show: true,
          },
          center: ["50%", "50%"],
        },
        series: [
          {
            type: "radar",
            animationDurationUpdate: 2000,
            animationEasingUpdate: "quinticInOut",
            symbolSize: 6,
            symbol: "diamond",
            emphasis: {
              focus: "series",
            },
            data: seriesData,
          },
        ],
        color: this.colorList,
      };
      this.updateChart("chart1", "chartOption1", option);
    },
    updateChart: function (refName, optionName, option = {}) {
      /* 渲染echart图 */
      if (!optionName) return;
      this[optionName] = option;
      setTimeout(() => {
        this.$refs[refName].updateChartView();
      }, 500);
    },
  },

  mounted() {
    this.getOperateWidth();
    if (!this.option.visible) {
      this.getData();
    }
  },
};
</script>

<style lang="less" scoped>
.carbonBox6 {
  .card-content1 {
    flex: 1;
    height: 100%;

    div {
      width: 100%;
      height: 100%;
    }
  }
  .card-content3 {
    display: flex;
  }
  /deep/ .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
</style>